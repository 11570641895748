/* tslint:disable */
/* eslint-disable */
/**
 * Volunteer Victoria - Community
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface OpportunityResponseDto
 */
export interface OpportunityResponseDto {
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  contactName: string;
  /**
   *
   * @type {number}
   * @memberof OpportunityResponseDto
   */
  requiredPeopleCount: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  occursDate: string;
  /**
   * Free-form text to describe when an opportunity occurs
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  occursTime: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  locationName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OpportunityResponseDto
   */
  indoorsOutdoorsOnline: Array<OpportunityResponseDtoIndoorsOutdoorsOnlineEnum>;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  contactPhone?: string;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityResponseDto
   */
  criminalRecordCheckRequired: boolean;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  idealVolunteer?: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  additionalInformation?: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  opportunityId: string;
  /**
   * Timestamp in millis when the opportunity was posted
   * @type {number}
   * @memberof OpportunityResponseDto
   */
  postedTime: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityResponseDto
   */
  postedByUserId: string;
}

/**
 * @export
 */
export const OpportunityResponseDtoIndoorsOutdoorsOnlineEnum = {
  Indoors: "indoors",
  Outdoors: "outdoors",
  Online: "online",
} as const;
export type OpportunityResponseDtoIndoorsOutdoorsOnlineEnum =
  typeof OpportunityResponseDtoIndoorsOutdoorsOnlineEnum[keyof typeof OpportunityResponseDtoIndoorsOutdoorsOnlineEnum];

/**
 * Check if a given object implements the OpportunityResponseDto interface.
 */
export function instanceOfOpportunityResponseDto(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "contactName" in value;
  isInstance = isInstance && "requiredPeopleCount" in value;
  isInstance = isInstance && "occursDate" in value;
  isInstance = isInstance && "occursTime" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "locationName" in value;
  isInstance = isInstance && "indoorsOutdoorsOnline" in value;
  isInstance = isInstance && "criminalRecordCheckRequired" in value;
  isInstance = isInstance && "opportunityId" in value;
  isInstance = isInstance && "postedTime" in value;
  isInstance = isInstance && "postedByUserId" in value;

  return isInstance;
}

export function OpportunityResponseDtoFromJSON(
  json: any
): OpportunityResponseDto {
  return OpportunityResponseDtoFromJSONTyped(json, false);
}

export function OpportunityResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpportunityResponseDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json["title"],
    contactName: json["contactName"],
    requiredPeopleCount: json["requiredPeopleCount"],
    occursDate: json["occursDate"],
    occursTime: json["occursTime"],
    description: json["description"],
    locationName: json["locationName"],
    indoorsOutdoorsOnline: json["indoorsOutdoorsOnline"],
    contactPhone: !exists(json, "contactPhone")
      ? undefined
      : json["contactPhone"],
    criminalRecordCheckRequired: json["criminalRecordCheckRequired"],
    idealVolunteer: !exists(json, "idealVolunteer")
      ? undefined
      : json["idealVolunteer"],
    additionalInformation: !exists(json, "additionalInformation")
      ? undefined
      : json["additionalInformation"],
    opportunityId: json["opportunityId"],
    postedTime: json["postedTime"],
    postedByUserId: json["postedByUserId"],
  };
}

export function OpportunityResponseDtoToJSON(
  value?: OpportunityResponseDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    contactName: value.contactName,
    requiredPeopleCount: value.requiredPeopleCount,
    occursDate: value.occursDate,
    occursTime: value.occursTime,
    description: value.description,
    locationName: value.locationName,
    indoorsOutdoorsOnline: value.indoorsOutdoorsOnline,
    contactPhone: value.contactPhone,
    criminalRecordCheckRequired: value.criminalRecordCheckRequired,
    idealVolunteer: value.idealVolunteer,
    additionalInformation: value.additionalInformation,
    opportunityId: value.opportunityId,
    postedTime: value.postedTime,
    postedByUserId: value.postedByUserId,
  };
}
