/* tslint:disable */
/* eslint-disable */
/**
 * Volunteer Victoria - Community
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface OpportunityCreateDto
 */
export interface OpportunityCreateDto {
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  contactName: string;
  /**
   *
   * @type {number}
   * @memberof OpportunityCreateDto
   */
  requiredPeopleCount: number;
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  occursDate: string;
  /**
   * Free-form text to describe when an opportunity occurs
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  occursTime: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  locationName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OpportunityCreateDto
   */
  indoorsOutdoorsOnline: Array<OpportunityCreateDtoIndoorsOutdoorsOnlineEnum>;
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  contactPhone?: string;
  /**
   *
   * @type {boolean}
   * @memberof OpportunityCreateDto
   */
  criminalRecordCheckRequired: boolean;
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  idealVolunteer?: string;
  /**
   *
   * @type {string}
   * @memberof OpportunityCreateDto
   */
  additionalInformation?: string;
}

/**
 * @export
 */
export const OpportunityCreateDtoIndoorsOutdoorsOnlineEnum = {
  Indoors: "indoors",
  Outdoors: "outdoors",
  Online: "online",
} as const;
export type OpportunityCreateDtoIndoorsOutdoorsOnlineEnum =
  typeof OpportunityCreateDtoIndoorsOutdoorsOnlineEnum[keyof typeof OpportunityCreateDtoIndoorsOutdoorsOnlineEnum];

/**
 * Check if a given object implements the OpportunityCreateDto interface.
 */
export function instanceOfOpportunityCreateDto(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "contactName" in value;
  isInstance = isInstance && "requiredPeopleCount" in value;
  isInstance = isInstance && "occursDate" in value;
  isInstance = isInstance && "occursTime" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "locationName" in value;
  isInstance = isInstance && "indoorsOutdoorsOnline" in value;
  isInstance = isInstance && "criminalRecordCheckRequired" in value;

  return isInstance;
}

export function OpportunityCreateDtoFromJSON(json: any): OpportunityCreateDto {
  return OpportunityCreateDtoFromJSONTyped(json, false);
}

export function OpportunityCreateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpportunityCreateDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json["title"],
    contactName: json["contactName"],
    requiredPeopleCount: json["requiredPeopleCount"],
    occursDate: json["occursDate"],
    occursTime: json["occursTime"],
    description: json["description"],
    locationName: json["locationName"],
    indoorsOutdoorsOnline: json["indoorsOutdoorsOnline"],
    contactPhone: !exists(json, "contactPhone")
      ? undefined
      : json["contactPhone"],
    criminalRecordCheckRequired: json["criminalRecordCheckRequired"],
    idealVolunteer: !exists(json, "idealVolunteer")
      ? undefined
      : json["idealVolunteer"],
    additionalInformation: !exists(json, "additionalInformation")
      ? undefined
      : json["additionalInformation"],
  };
}

export function OpportunityCreateDtoToJSON(
  value?: OpportunityCreateDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    contactName: value.contactName,
    requiredPeopleCount: value.requiredPeopleCount,
    occursDate: value.occursDate,
    occursTime: value.occursTime,
    description: value.description,
    locationName: value.locationName,
    indoorsOutdoorsOnline: value.indoorsOutdoorsOnline,
    contactPhone: value.contactPhone,
    criminalRecordCheckRequired: value.criminalRecordCheckRequired,
    idealVolunteer: value.idealVolunteer,
    additionalInformation: value.additionalInformation,
  };
}
