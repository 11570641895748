/* tslint:disable */
/* eslint-disable */
/**
 * Volunteer Victoria - Community
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  OpportunityCreateDto,
  OpportunityResponseDto,
  ThreadStartDto,
} from "../models";
import {
  OpportunityCreateDtoFromJSON,
  OpportunityCreateDtoToJSON,
  OpportunityResponseDtoFromJSON,
  OpportunityResponseDtoToJSON,
  ThreadStartDtoFromJSON,
  ThreadStartDtoToJSON,
} from "../models";

export interface MessageControllerPostRequest {
  opportunityId: string;
  threadStartDto: ThreadStartDto;
}

export interface OpportunityControllerDeleteIdRequest {
  id: string;
}

export interface OpportunityControllerGetRequest {
  minOccursDate?: any;
}

export interface OpportunityControllerGetIdRequest {
  id: string;
}

export interface OpportunityControllerPostRequest {
  opportunityCreateDto: OpportunityCreateDto;
}

export interface OpportunityControllerPostFakeRequest {
  count?: string;
}

export interface OpportunityControllerPutIdRequest {
  id: string;
  opportunityCreateDto: OpportunityCreateDto;
}

export interface RootControllerDebugRequest {
  statusCode: number;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   */
  async messageControllerPostRaw(
    requestParameters: MessageControllerPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.opportunityId === null ||
      requestParameters.opportunityId === undefined
    ) {
      throw new runtime.RequiredError(
        "opportunityId",
        "Required parameter requestParameters.opportunityId was null or undefined when calling messageControllerPost."
      );
    }

    if (
      requestParameters.threadStartDto === null ||
      requestParameters.threadStartDto === undefined
    ) {
      throw new runtime.RequiredError(
        "threadStartDto",
        "Required parameter requestParameters.threadStartDto was null or undefined when calling messageControllerPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.opportunityId !== undefined) {
      queryParameters["opportunityId"] = requestParameters.opportunityId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "bearer",
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/message`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ThreadStartDtoToJSON(requestParameters.threadStartDto),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async messageControllerPost(
    requestParameters: MessageControllerPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.messageControllerPostRaw(requestParameters, initOverrides);
  }

  /**
   * Delete all opportunities
   */
  async opportunityControllerDeleteAllRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "bearer",
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/opportunity`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete all opportunities
   */
  async opportunityControllerDeleteAll(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.opportunityControllerDeleteAllRaw(initOverrides);
  }

  /**
   */
  async opportunityControllerDeleteIdRaw(
    requestParameters: OpportunityControllerDeleteIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OpportunityResponseDto>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling opportunityControllerDeleteId."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "bearer",
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/opportunity/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OpportunityResponseDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async opportunityControllerDeleteId(
    requestParameters: OpportunityControllerDeleteIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OpportunityResponseDto> {
    const response = await this.opportunityControllerDeleteIdRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async opportunityControllerGetRaw(
    requestParameters: OpportunityControllerGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<OpportunityResponseDto>>> {
    const queryParameters: any = {};

    if (requestParameters.minOccursDate !== undefined) {
      queryParameters["minOccursDate"] = requestParameters.minOccursDate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/opportunity`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(OpportunityResponseDtoFromJSON)
    );
  }

  /**
   */
  async opportunityControllerGet(
    requestParameters: OpportunityControllerGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<OpportunityResponseDto>> {
    const response = await this.opportunityControllerGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async opportunityControllerGetIdRaw(
    requestParameters: OpportunityControllerGetIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OpportunityResponseDto>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling opportunityControllerGetId."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/opportunity/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OpportunityResponseDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async opportunityControllerGetId(
    requestParameters: OpportunityControllerGetIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OpportunityResponseDto> {
    const response = await this.opportunityControllerGetIdRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async opportunityControllerPostRaw(
    requestParameters: OpportunityControllerPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OpportunityResponseDto>> {
    if (
      requestParameters.opportunityCreateDto === null ||
      requestParameters.opportunityCreateDto === undefined
    ) {
      throw new runtime.RequiredError(
        "opportunityCreateDto",
        "Required parameter requestParameters.opportunityCreateDto was null or undefined when calling opportunityControllerPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "bearer",
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/opportunity`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: OpportunityCreateDtoToJSON(
          requestParameters.opportunityCreateDto
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OpportunityResponseDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async opportunityControllerPost(
    requestParameters: OpportunityControllerPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OpportunityResponseDto> {
    const response = await this.opportunityControllerPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async opportunityControllerPostFakeRaw(
    requestParameters: OpportunityControllerPostFakeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<OpportunityResponseDto>>> {
    const queryParameters: any = {};

    if (requestParameters.count !== undefined) {
      queryParameters["count"] = requestParameters.count;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "bearer",
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/opportunity/fake`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(OpportunityResponseDtoFromJSON)
    );
  }

  /**
   */
  async opportunityControllerPostFake(
    requestParameters: OpportunityControllerPostFakeRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<OpportunityResponseDto>> {
    const response = await this.opportunityControllerPostFakeRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async opportunityControllerPutIdRaw(
    requestParameters: OpportunityControllerPutIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OpportunityResponseDto>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling opportunityControllerPutId."
      );
    }

    if (
      requestParameters.opportunityCreateDto === null ||
      requestParameters.opportunityCreateDto === undefined
    ) {
      throw new runtime.RequiredError(
        "opportunityCreateDto",
        "Required parameter requestParameters.opportunityCreateDto was null or undefined when calling opportunityControllerPutId."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "bearer",
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/opportunity/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: OpportunityCreateDtoToJSON(
          requestParameters.opportunityCreateDto
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OpportunityResponseDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async opportunityControllerPutId(
    requestParameters: OpportunityControllerPutIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OpportunityResponseDto> {
    const response = await this.opportunityControllerPutIdRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Return debug response for testing out e.g. alarms
   */
  async rootControllerDebugRaw(
    requestParameters: RootControllerDebugRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.statusCode === null ||
      requestParameters.statusCode === undefined
    ) {
      throw new runtime.RequiredError(
        "statusCode",
        "Required parameter requestParameters.statusCode was null or undefined when calling rootControllerDebug."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.statusCode !== undefined) {
      queryParameters["statusCode"] = requestParameters.statusCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "bearer",
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/debug`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Return debug response for testing out e.g. alarms
   */
  async rootControllerDebug(
    requestParameters: RootControllerDebugRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.rootControllerDebugRaw(requestParameters, initOverrides);
  }

  /**
   */
  async rootControllerHelloRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/hello`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async rootControllerHello(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.rootControllerHelloRaw(initOverrides);
  }
}
